<template>
    <div class="flex flex-col bg-white py-6 mt-6">
        <div class="text-3xl font-bold text-start mx-16">
            Sin ideas para armar tu canastita?
        </div>
        <div class="py-6"></div>
        <div class="text-xl text-start mx-16">
            No te preocupes, Confitel te trae algunos listado de sugerencias con productos bases tales como:<br>
            Jugos, chocolates, masticables, chicles, pastillas y muchas más sorpresas.
        </div>
        <div class="flex flex-row gap-4 mx-auto justify-center content-start mt-4">
            <div v-for="c,index in canastas" :key="c">
                <div class="flex">
                    <div class="relative w-[180px] mx-auto">
                        <img :src="nube" :alt="'precio'" class="w-full"/>
                        <div class="absolute inset-0 text-black flex items-center justify-center text-black">{{formatCurrency(c.precio)}}</div>
                    </div>
                </div>
                <div class="flex mt-4">
                    <img :src="'static'+c.path" :alt="'categoria'" class="mb-4 w-[180px] object-cover mx-auto"/>
                </div>
                <div>{{c.titulo}}</div>
                <div>Opcion {{ index+1 }}</div>
                <div class="flex flex-row space-x-4">
                    <button @click=verLista(index+1) class="w-[200px] h-[30px] bg-yellow-400 text-white text-[20px] rounded-xl">Ver lista completa</button>
                    <button @click="handleAddToCart({id:c.id,name:c.titulo,price:c.precio,img:c.path})" class="w-[200px] h-[30px] bg-yellow-400 text-white text-[20px] rounded-xl">Comprar lista</button>
                </div>
                <div :id="'canatas_'+(index+1)" class="flex flex-col mt-4 hidden">
                    <div v-for="p in c.productos" :key="p" class="flex flex-row">
                        <div class="w-[200px]">{{p.nombre}}</div>
                        <div class="w-[200px]">{{formatCurrency(p.precio)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import nube from "@/assets/canasta/NubePrecio.png";
export default {
    name: "Pagina6Home",
    props: {
        canastas: Object
    },
    data() {
        return {
            nube: nube,
            celular: require("@/assets/celu.png"),
        };
    },
    methods: {
        verLista(index) {
            document.getElementById('canatas_'+index).classList.toggle('hidden');
        },
        handleAddToCart(product) {
            window.addToCart(product);
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: 'CLP'
            }).format(value);
        }
    }
}
</script>