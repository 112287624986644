<template>
    <NavBar :user="user"></NavBar>
    <div class="flex flex-row p-10">
        <a href="/">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                Volver al inicio
            </button>
        </a>
        <button @click="open_modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Agregar Colacion
        </button>
    </div>
    
    <div class="overflow-x-auto">
        <h1 class="text-2xl font-bold mb-4">Colaciones de empresas</h1>
        <table class="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead class="bg-blue-700 text-white">
            <tr>
                <th class="py-2 px-4">Título</th>
                <th class="py-2 px-4">Imagen</th>
                <th class="py-2 px-4">Productos</th>
                <th class="py-2 px-4">Precio</th>
                <th class="py-2 px-4">Precio oferta</th>
                <th class="py-2 px-4">Colacion</th>
                <th class="py-2 px-4">Acciones</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="colacion in colaciones" :key="colacion.id" class="border-b">
                <td class="py-2 px-4">{{ colacion.titulo }}</td>
                <td class="py-2 px-4">
                    <img :src="'static'+colacion.path" :alt="'preview'" class="mx-auto mb-4 w-[200px] h-[200px]"/>
                </td>
                <td class="py-2 px-4">
                    <div class="flex flex-col">
                        <div v-for="p in colacion.productos" :key="p">
                            {{ p.nombre }} - ${{ p.precio }} <button @click="eliminarProducto(p.id)">❌</button>
                        </div>
                    </div>
                    
                </td>
                <td class="py-2 px-4">{{ colacion.precio }}</td>
                <td class="py-2 px-4">{{ colacion.precio_oferta }}</td>
                <td class="py-2 px-4">
                    <div v-if="colacion.colacion">
                        Si
                    </div>
                    <div v-else>
                        No
                    </div>
                </td>
                <td class="py-2 px-4 flex space-x-2">
                    <button @click="agregarProducto(colacion.id)" class="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 rounded">Agregar al producto</button>
                    <button @click="open_edit_modal(colacion)" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-3 rounded">Editar</button>
                    <button @click="deleteColacion(colacion.id)" class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded">Eliminar</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div id="modal_agregar" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-2xl max-h-full mx-auto mt-20">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Agregar Colacion
                    </h3>
                    <button @click="close_modal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <label for="input_nombre" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre</label>
                    <input v-model="titulo" type="text" id="input_nombre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <div class="flex flex-col md:flex-row mx-auto justify-center w-10/12">
                        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Subir Imagen (Formatos permitidos png, jpg y jpeg)</label>
                        <input accept="image/png, image/jpeg, image/jpg" class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file">
                    </div>
                    <label for="input_precio" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Precio</label>
                    <input v-model="precio" type="text" id="input_categoria" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <label for="input_precio_oferta" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Precio oferta (Opcional)</label>
                    <input v-model="precio_oferta" type="text" id="input_precio_oferta" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <select v-model="colacion" id="carrusel" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value=0>Canasta</option>
                        <option value=1>Colacion</option>
                    </select>
                </div>
                <!-- Modal footer -->
                <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button id="btn_editar" @click="editColacion" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Editar</button>
                    <button id="btn_agregar" @click="addColacion" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Agregar</button>
                    <button @click="close_modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancelar</button>
                </div>
            </div>
        </div>
    </div>

    <div id="modal_agregar_producto" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-2xl max-h-full mx-auto mt-20">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        Agregar producto
                    </h3>
                    <button @click="close_modal_producto" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <label for="input_nombre" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre</label>
                    <input v-model="nombreProducto" type="text" id="input_nombre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <label for="input_precio" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Precio</label>
                    <input v-model="precioProducto" type="text" id="input_categoria" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>
                <!-- Modal footer -->
                <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button id="btn_agregar_producto" @click="addProductoColacion" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Agregar</button>
                    <button @click="close_modal_producto" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import NavBar from '@/components/Navbar.vue'
  import Swal from "sweetalert2";
  export default {
    components:{NavBar},
    data() {
      return {
        id:0,
        titulo:'',
        path:'',
        precio:0,
        precio_oferta:0,
        colaciones: [],
        nombreProducto:'',
        precioProducto:0,
        colacion:1,
        admin:false,
        user:null
      };
    },
    mounted() {
      window.load_axios('/get_colaciones',1);
      window.load_axios('chequeo_sesion',7);
      window.select_funcion = this.select_funcion;
    },
    methods: {
        eliminarProducto(id){
            Swal.fire({
                title: '¿Estás seguro?',
                text: "Quieres borrar este producto?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, bórralo!'
            }).then((result) => {
                if (result.isConfirmed) {

                    let formData = new FormData();
                    formData.append('id', id);
                    window.load_response('/delete_producto_colacion',formData,6);
                } 
            });
        },
        close_modal_producto(){
            document.getElementById('modal_agregar_producto').classList.add('hidden');
        },
        agregarProducto(id){
            this.id=id;
            this.nombreProducto='';
            this.precioProducto=0;
            document.getElementById('modal_agregar_producto').classList.remove('hidden');
        },
        addProductoColacion(){
            let formData = new FormData();
            formData.append('id', this.id);
            formData.append('nombre', this.nombreProducto);
            formData.append('precio', this.precioProducto);
            window.load_response('/add_producto_colacion',formData,5);
        },
        clean_vars(){
            this.id=0;
            this.titulo='';
            this.path='';
            this.precio=0;
            this.precio_oferta=0;
            this.colacion=1;
        },
        open_edit_modal(colacion){
            this.id = colacion.id;
            this.titulo = colacion.titulo;
            this.path = colacion.path;
            this.precio = colacion.precio;
            this.precio_oferta = colacion.precio_oferta;
            if(colacion.colacion == true){
                this.colacion = 1;
            }else{
                this.colacion = 0;
            }
            document.getElementById('modal_agregar').classList.remove('hidden');
            document.getElementById('btn_agregar').classList.add('hidden');
            document.getElementById('btn_editar').classList.remove('hidden');
        },
        open_modal(){
            this.clean_vars();
            document.getElementById('modal_agregar').classList.remove('hidden');
            document.getElementById('btn_agregar').classList.remove('hidden');
            document.getElementById('btn_editar').classList.add('hidden');
        },
        close_modal(){
            document.getElementById('modal_agregar').classList.add('hidden');
        },
        editColacion() {
            let formData = new FormData();
            formData.append('id', this.id);
            formData.append('titulo', this.titulo);
            if(document.getElementById("file_input").files[0]){
                formData.append('path', document.getElementById("file_input").files[0]);
            }
            formData.append('precio', this.precio);
            formData.append('precio_oferta', this.precio_oferta);
            formData.append('colacion', this.colacion);
            window.load_response('/update_colacion',formData,4);
        },
        addColacion() {
            let formData = new FormData();
            formData.append('titulo', this.titulo);
            formData.append('path', document.getElementById("file_input").files[0]);
            formData.append('precio', this.precio);
            formData.append('precio_oferta', this.precio_oferta);
            formData.append('colacion', this.colacion);
            window.load_response_file('/add_colacion',formData,2);            
        },
        deleteColacion(id) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "Quieres borrar esta colacion?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, bórralo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let formData = new FormData();
                    formData.append('id', id);
                    window.load_response('/delete_colacion',formData,3);
                }
            });
        },
        select_funcion(response, numero_funcion){
            switch(numero_funcion){
                case 1:
                    this.colaciones = response.data.colaciones;
                    break;
                case 2:
                    Swal.fire({
                        title: 'Colacion agregada',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    this.close_modal();
                    window.load_axios('/get_colaciones',1);
                    break;
                case 3:
                    Swal.fire({
                        title: 'Colacion eliminado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    window.load_axios('/get_colaciones',1);
                    break;
                case 4:
                    Swal.fire({
                        title: 'Colacion editado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    this.close_modal();
                    window.load_axios('/get_colaciones',1);
                    break;
                case 5:
                    Swal.fire({
                        title: 'Producto agregado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    this.close_modal_producto();
                    window.load_axios('/get_colaciones',1);
                    break;
                case 6:
                    Swal.fire({
                        title: 'Producto eliminado',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    window.load_axios('/get_colaciones',1);
                    break;
                case 7:
                    if(response.data['admin']){
                        this.admin = true;
                    }else{
                        window.location.href = '/login';
                    }
                    this.user = response.data['user'];
                    break;
            }

        }
    }
  }
  </script>
  